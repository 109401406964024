import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import './css2/main1.css';
import UClogo from '../images/UClogo.png';

//use {window.location.pathname} to compare path with current and disallow stacking multiple of same page on route stack

//ADD THIS BACK IN (below) 
//<button className='menuButton' onClick={() => history.push('/contact')}>Contact</button>


function Menu(props) {
	//Needs props:
	//title
	//userInfo
	//components  (all buttons plz and ty)
	
	const history = useHistory();
	const location = useLocation();
	const [userInfo, setUserInfo] = useState(props.userInfo);

	return (
		<div className='menuPanel'>
			<div className='logoContainer'> 
				<img onClick={() => history.push('/')} src={UClogo} className='logo' /> 
			</div>
			<div className='loggedIn'>
				<p>Logged in:&nbsp;</p>
				<p style={{ color: '#40ff0f' }}>{userInfo.username}</p>
			</div>
			{backButton()}
			{renderAP()}
			{props.components}
			{/*<button className='menuButton' onClick={() => history.push('/contact')}>Contact</button>*/}
			{/*<button className='menuButton' onClick={() => history.push('/guide')}>Guide</button>*/}
			<button className='menuButton' onClick={() => history.push('/account', { userInfo: userInfo })}>Settings</button>
			{/*<p className='menuBottomText' onClick={() => history.push('/contact')}>Contact</p>*/}
		</div>
	);

	function backButton() {
		if (props.logout == true) {
			return (<button className='menuButton' onClick={() => history.goBack()}>Logout</button>);
		}
		else {
			return (<button className='menuButton' onClick={() => history.goBack()}>Go Back</button>);
        }
    }

	function renderAP() {
		if (userInfo.perm > 1) {
			return (
				<button className='menuButton' onClick={() => history.push('/admin', { userInfo: userInfo })}>Admin Panel</button>
			);
        }
    }
}

export default Menu;