import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { RxQuestionMarkCircled } from 'react-icons/rx';

import Menu from './Menu.js';
import './css2/main1.css'; 
import TeamViewer from './TeamViewer.js';
import UserViewer from './UserViewer.js'; 
import Alert from './Alert.js'; 
import Loading from './Loading.js'; 



function Admin() {
	const location = useLocation();
	const history = useHistory(); 
	const [userInfo, setUserInfo] = useState(location.state.userInfo);

	const [usersList, setUsersList] = useState([]); //data array
	const [teamsList, setTeamsList] = useState([]); //..

	const [selectedTeam, setSelectedTeam] = useState(); //relays which team is selected to pass to TeamViewer component 
	const [isTeamViewerOpen, setIsTeamViewerOpen] = useState(false); //bool = show content

	const [selectedUser, setSelectedUser] = useState(); //same org as teamViewer, above 
	const [isUserViewerOpen, setIsUserViewerOpen] = useState(false); 

	const [showAddUser, setShowAddUser] = useState(false); //bools controlling whether to show input forms
	const [showAddTeam, setShowAddTeam] = useState(false);

	const [showAlert, setShowAlert] = useState(false); //standard alert popup
	const [alertMsg, setAlertMsg] = useState(); 

	const [showAlertDeleteUser, setShowAlertDeleteUser] = useState(false); // 'are you sure?' popups for delete actions
	const [showAlertDeleteTeam, setShowAlertDeleteTeam] = useState(false); 
	const [deleteWhat, setDeleteWhat] = useState(); 

	const [dateRange, setDateRange] = useState([]); //date range for commentCount. 


	var mounted;
	useEffect(() => {
		mounted = true;
		refreshUsers();
		refreshTeams(); 

		//set default dateRange.. possibly grab from a DB_dateRangeDefault
		let date1 = new moment().subtract(1, "years"); //ytd
		let date2 = new moment(); //current date
		setDateRange([date1, date2]);

		const interval = setInterval(() => {
			refreshUsers();
			refreshTeams(); 
		}, 1500)

		return (() => {
			mounted = false;
			clearInterval(interval);
		}); //mounted is checked before fetching to ensure no fetches are done for unmounted components
	}, []);


	//MAIN RENDER
	return (
		<div className='page'>
			<Menu
				title={'Admin Panel'}
				userInfo={userInfo}
				components={[
				]}
			/> 
			{showAlert && <Alert 
				continueHandler={toggleAlert}
				msg={alertMsg}
			/>}
			{showAlertDeleteUser && <Alert
				continueHandler={continueDeleteUser}
				cancelHandler={cancelDeleteUser}
				msg={alertMsg}
			/>}
			{showAlertDeleteTeam && <Alert
				continueHandler={continueDeleteTeam}
				cancelHandler={cancelDeleteTeam}
				msg={alertMsg}
			/>}
			<ReactTooltip />
			<div className='contentPanel'> 
				<h1 className='title'>Admin Panel</h1>
				{isTeamViewerOpen && <TeamViewer
					handleClose={toggleTeamViewer}
					team={selectedTeam}
					userInfo={userInfo} 
				/>}
				{isUserViewerOpen && <UserViewer
					handleClose={toggleUserViewer}
					user={selectedUser}
					userInfo={userInfo}
				/>}
				<div className='APcontainer'>
					<div className='userContainer'>
						<h1 className='APsubtitle'>Users</h1>
						{/*renderAddUser()*/}
						{renderUsers()}
					</div>
					<div className='teamContainer'>
						<h1 className='APsubtitle'>Teams</h1>
						{renderAddTeam()}
						{renderTeams()}
					</div>
				</div> 
			</div> 
		</div>
	);
	//END MAIN RENDER



	//	USERS AND PERTAINING FUNCTIONS
	async function refreshUsers() {
		await fetch('https://slashsolutions.co/UComment/getAccts.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'targetdb=' + userInfo.db
		})
			.then((resp) => resp.json())
			.then((resp) => {
				if (Array.isArray(resp)) {
					setUsersList(resp);
				}
				else {
					console.log("skipped setUserList()");
					return;
				}
			})
			.catch((error) => {
				console.log("Error: " + error);
			})
	}

	function renderAddUser() {
		if (showAddUser) {
			return (
				<div className='APaddForm'>
					<div className='tightRow'>
						<label>Username: </label>
						<input type='text' id='username' />
					</div>
					<div className='tightRow'>
						<label>Password: </label>
						<input type='text' id='userpass' />
					</div>
					<div className='tightRow'>
						<label>Permission: </label>
						<input type='number' id='userperm' min='0' max='2' />
					</div>
					<div className='tightRow' style={{ marginTop: '12px' }}>
						<button onClick={() => toggleAddUser()}>Cancel</button>
						<button onClick={() => addAcct()}>Submit</button>
					</div>
				</div>
			);
		}
		else {
			return (
				<div className='APaddForm2'>
					<button className='APaddFormButton' onClick={() => toggleAddUser()}>Add User</button>
				</div>
			);
		}
	}

	function renderUsers() {
		if (usersList.length === 0) {
			return (
				<div>
					<Loading />
				</div>
			)
		}
		else {
			return (
				<div>
					<ReactTooltip />
					<table className='usersTable'>
						<tr>
							<th>User</th>
							<th data-tip={renderActivityDataTip()} data-padding="10px" data-effect="solid">Ridealongs <RxQuestionMarkCircled size={15}/></th>
							<th>Teams</th> 
							<th data-tip="2 admin | 1 user | 0 disabled" data-padding="10px" data-effect="solid">Perm <RxQuestionMarkCircled size={15}/></th>
							<th>Edit</th> 
							{/*--<th>Delete</th>--*/}
						</tr>
						{usersList.map((user) =>
							<tr id={user.acctname} draggable={true} onDrop={onDropTeamToUser} onDragOver={onDragOverUser}>
								<td id={user.acctname}>{user.acctname}</td>
								<td id={user.acctname}>{user.commentcount}</td>
								<td id={user.acctname}>{user.teamstr}</td> 
								<td id={user.acctname}>{user.perm}</td>
								<td className='clickableCell' id={user.acctname} onClick={() => toggleUserViewer(user)}>Edit</td> 
								{/*--<td className='clickableCell' id={user.acctname} onClick={() => deleteUserAuth(user.acctname)}>Delete</td>--*/}
							</tr>
						)}
					</table>
				</div>
			)
		}
	}

	function renderActivityDataTip() {
		let str = "# of posts " + dateRange[0].format("MM/DD/YYYY") + " through " + dateRange[1].format("MM/DD/YYYY");
		return str;
	}

	function toggleUserViewer(sel) {
		setSelectedUser(sel);
		console.log('selected user array:');
		console.log(sel);
		setIsUserViewerOpen(!isUserViewerOpen);
	}

	function toggleAddUser() {
		setShowAddUser(!showAddUser);
	}

	function deleteUserAuth(acct) {
		setDeleteWhat(acct);
		setAlertMsg('Are you sure you want to permanently delete ' + acct);
		setShowAlertDeleteUser(true);
	}

	function continueDeleteUser() {
		console.log('deleting ' + deleteWhat); 
		setShowAlertDeleteUser(false);
		deleteAcct(deleteWhat);
	}

	function cancelDeleteUser() {
		setShowAlertDeleteUser(false);
	}

	async function deleteAcct(acct) {
		await fetch('https://slashsolutions.co/UComment/deleteAcct.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'target=' + acct + '&db=' + userInfo.db
		})
			.then((resp) => resp.json())
			.then((resp) => {
				console.log(resp);
			})
			.catch((error) => {
				console.log('myerror: ' + error);
			})
	}

	async function addAcct() {
		toggleAddUser();

		let name = document.getElementById('username').value;
		let pass = document.getElementById('userpass').value;
		let perm = document.getElementById('userperm').value;

		if (name == null || pass == null || perm == null) {
			console.log('not enough info!');
			return;
		}
		if (perm > 2 || perm < 0) {
			return;
        }

		await fetch('https://slashsolutions.co/UComment/addAcct.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'user=' + name + '&pass=' + pass + '&db=' + userInfo.db + '&perm=' + perm
		})
			.then((resp) => resp.json())
			.then((resp) => {
				console.log(resp);
			})
			.catch((error) => {
				console.log('myerror: ' + error);
			})
	}
	//  END USER SIDE



	//	TEAM SIDE AND PERTAINING FUNCTIONS
	async function refreshTeams() {
		await fetch('https://slashsolutions.co/UComment/getTeams.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'targetdb=' + userInfo.db
		})
			.then((resp) => resp.json())
			.then((resp) => {
				setTeamsList(resp);
			})
			.catch((error) => {
				console.log("Error: " + error);
			})
	}

	function renderTeams() {
		if (teamsList.length === 0) {
			console.log('teamsList.length = 0');
			console.log(teamsList);
			return (
				<div>
					<Loading /> 
				</div>
			)
		}
		else {
			return (
				<div>
					<ReactTooltip />
					<table>
						<tr>
							<th>Team</th>
							<th>Focus</th>
							{/*<th>Delete</th>*/}
						</tr>
						{teamsList.map((team) => {
							let element = <tr id={team.team} className='draggable' draggable="true" onDragStart={dragStart}>
								<td data-tip="Drag me onto account username to add permissions to view me" data-padding='10px' data-effect='solid'>{team.team}</td>
								<td className='clickableCell' onClick={() => toggleTeamViewer(team.team)}>Focus</td>
								{/*<td className='clickableCell' onClick={() => deleteTeamAuth(team.team)}>Delete</td>*/}
							</tr>

							return (element);
						})}
					</table>
				</div>
			)
		}
	}

	function dragStart(event) {
		event.dataTransfer.setData("text/plain", event.target.id);
		event.dataTransfer.dropEffect = "copy"; //when hovered over legal drop, will show that value is copied
	}

	function renderAddTeam() {
		if (showAddTeam) {
			return (
				<div className='APaddForm'>
					<div className='tightRow'>
						<label>Team Name: </label>
						<input type='text' id='teamname' />
					</div>
					<div className='tightRow' style={{ marginTop: '12px' }}>
						<button onClick={() => toggleAddTeam()}>Cancel</button>
						<button onClick={() => createTeam()}>Submit</button>
					</div>
				</div>
			);
		}
		else {
			return (
				<div className='APaddForm2'>
					<button className='APaddFormButton' onClick={() => toggleAddTeam()}>Add Team</button>
				</div>
			);
		}
	}

	function toggleTeamViewer(sel) {
		setSelectedTeam(sel);
		setIsTeamViewerOpen(!isTeamViewerOpen);
	}

	function toggleAddTeam() {
		setShowAddTeam(!showAddTeam);
	}

	async function createTeam() {
		toggleAddTeam();

		let name = document.getElementById('teamname').value;
		await fetch('https://slashsolutions.co/UComment/createTeam.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'name=' + name + '&db=' + userInfo.db
		})
			.then((resp) => resp.json())
			.then((resp) => {
				console.log(resp);
			})
			.catch((error) => {
				console.log('myerror: ' + error);
			})
	}

	function deleteTeamAuth(team) {
		setDeleteWhat(team);
		setAlertMsg('Are you sure you want to permanently delete ' + team);
		setShowAlertDeleteTeam(true);
	}

	function continueDeleteTeam() {
		console.log('i made it tho');
		setShowAlertDeleteTeam(false);
		deleteTeam(deleteWhat);
	}

	function cancelDeleteTeam() {
		setShowAlertDeleteTeam(false);
	}

	async function deleteTeam(team) {
		await fetch('https://slashsolutions.co/UComment/deleteTeam.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'team=' + team + '&db=' + userInfo.db
		})
			.then((resp) => resp.json())
			.then((resp) => {
				if (resp.answer == 'fail') {
					setAlertMsg(resp.data);
					toggleAlert();
				}
			})
			.catch((error) => {
				console.log('myerror: ' + error);
			})
	}
	//END TEAM SIDE


	//	COOPERATIVE FUNCTIONS BETWEEN USER AND TEAM
	async function addTeamToUser(name, team) {
		await fetch('https://slashsolutions.co/UComment/addTeam.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'targetdb=' + userInfo.db + '&acctName=' + name + '&team=' + team
		})
			.then((resp) => resp.json())
			.then((resp) => {
				if (resp != "success") {
					setAlertMsg(resp);
					toggleAlert();
				}
				else {
					refreshUsers();
                }
			})
			.catch((error) => {
				console.log('myerror: ' + error);
			})
	}

	function onDropTeamToUser(event) {
		let team = event.dataTransfer.getData('text/plain'); //string 'team name'
		if (team == '' || team == null) return;
		addTeamToUser(event.target.id, team); //event.target.id refers to the element receiving the drop event. All table cells have id of user.acctname for this reason
	}
	function onDragOverUser(event) {
		event.preventDefault();
	}




	function toggleAlert() {
		setShowAlert(!showAlert);
	}

	
}

export default Admin; 