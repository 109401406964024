import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import './css2/contact.css';
import Alert from './Alert.js'; 


function Contact() {
	const [showAlert, setShowAlert] = useState(false); 
	const [alertMsg, setAlertMsg] = useState(); 

	const history = useHistory(); 
	const location = useLocation(); 


	//MAIN RENDER
	return (
		<div className='page'>
			{showAlert && <Alert
				continueHandler={toggleAlert}
				msg={alertMsg}
			/>}
			<div className='content'>
				<button onClick={() => toggleAlert()}>alert</button>
				<h1 className='contactTitle'>Contact</h1>
				<p className='description'>If you have any questions about UComment or have encountered a bug, please reach out
				using the fields below and I will get back to you promptly to assist. If you would rather use your personal email client,
				all correspondence can be directed to <b>tyler@slashsolutions.co</b></p> 
				<div className='mailForm'>
					<input id='inputAddr' type='text' className='mailSubject' placeholder={'Return email address'} />
					<br /> 
					<input id='inputSubj' type='text' className='mailSubject' placeholder={'Subject'}/>
					<br />
					<textarea id='inputBody' className='mailBody' placeholder={'Email Body'} />
					<div className='buttonRow'>
						<button className='contactSubmitButton' onClick={() => history.goBack()}>Cancel</button> 
						<button className='contactSubmitButton' onClick={() => sendMail()}>Submit</button>
					</div>
				</div> 
			</div> 
		</div> 
	); 

	async function sendMail() {
		var addr = document.getElementById('inputAddr').value;
		var subj = document.getElementById('inputSubj').value;
		var body = document.getElementById('inputBody').value; 

		setAlertMsg("Message successfully sent!");
		toggleAlert();

		await fetch('https://slashsolutions.co/UComment/mailMe.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'returnadd=' + addr + '&subject=' + subj + '&body=' + body
		})
			.then((resp) => resp = resp.json())
			.then((resp) => {
				console.log(resp); 
			})
			.catch((error) => {
				console.log('Error' + error)
			})
    }

	function toggleAlert() {
		setShowAlert(!showAlert); 
	}
}

export default Contact; 