import React, { useState, useEffect } from 'react'; 

import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer
} from 'recharts';

import './css2/graph.css'; 



function GraphPopup(props) {
	/* NEEDS PROPS
	 * employeeName
	 * userInfo
	 * handleClose
	 * */

	const [ratingsArray, setRatingsArray] = useState([]); 

	var mounted;
	useEffect(() => {
		mounted = true;

		refreshRatings();

		return (() => {
			mounted = false
		});
	}, []);


	//MAIN RENDER
	return (
		<div className='page'>
			<div className='content'>
				<div className='row'>
					<div className='optionsBar'>
						<p>Option</p>
						<p>Option</p>
						<p>Option</p>
						<p>Option</p>
					</div>
					<div className='column'>
						<BarChart
							width={800}
							height={600}
							data={ratingsArray}
							margin={{
								top: 45,
								right: 40,
								left: 40,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="10 10" />
							<XAxis dataKey="date" />
							<YAxis
								tickCount={11}
								domain={[0, 10]}
								interval={'preserveEnd'}
									minTickGap={0}
								tickMargin={0}
								allowDataOverflow={true}
								allowDecimals={false}
								width={10}
							/>
							<Legend />
							<Bar dataKey="myRating" fill="#64B6AC" />
							<Bar dataKey="theirRating" fill="#2A324B" />
						</BarChart>
						<button className='closeButton' onClick={() => props.handleClose()}>Close</button>
					</div> 
				</div> 
			</div>
		</div>
	);

	async function refreshRatings() {
		if (!mounted) {
			return;
        }

		await fetch('https://slashsolutions.co/UComment/getAllRatings.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'employeeName=' + props.employeeName + '&targetdb=' + props.userInfo.db
		}) 
			.then((resp) => resp.json())
			.then((resp) => {
				console.log(resp);
				setRatingsArray(resp); 
			})
			.catch((error) => {
				console.log('myError: ' + error); 
            })
    }
}

export default GraphPopup; 
