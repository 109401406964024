import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import './css2/main1.css';
import Menu from './Menu.js';



function Goals() {
	const history = useHistory(); 
	const location = useLocation(); 

	const [userInfo, setUserInfo] = useState(location.state.userInfo);
	const [employeeName, setEmployeeName] = useState(location.state.employee); 
	const [goalsList, setGoalsList] = useState([]);
	const [showAddGoal, setShowAddGoal] = useState(false); 

	let mounted;
	useEffect(() => {
		mounted = true;
		refreshGoals();

		const interval = setInterval(() => {
			refreshGoals()
		}, 1500)

		return (() => {
			clearInterval(interval);
			mounted = false
		}); //mounted is checked before fetching to ensure no fetches are done for unmounted components
	}, []);

	//main render
	return (
		<div className='page'>
			<Menu
				userInfo={userInfo}
				components={[
				]}
			/>
			<div className='contentPanel'>
				<h1 className='title'>{employeeName}'s Goals</h1>
				<div className='list'>
					{renderAddGoal()}
					{renderGoals()}
				</div> 
			</div> 
		</div>
	);

	function toggleShowAddGoal() {
		setShowAddGoal(!showAddGoal); 
    }

	function renderAddGoal() {
		if (showAddGoal) {
			return (
				<div className='tightRow'>
					<label>Goal: </label>
					<input type='text' id='goalstring' />
					<br /> 
					<button onClick={() => toggleShowAddGoal()}>Cancel</button>
					<button onClick={() => addGoal(document.getElementById('goalstring').value)}>Submit</button> 
				</div> 
			);
		}
		else {
			return (
				<div className='tightRow'>
					<button className='goalsButton' onClick={() => toggleShowAddGoal()}>Add Goal</button>
				</div> 
			);
        }
    }

	function toggleShowAddGoal() {
		setShowAddGoal(!showAddGoal); 
    }

	function renderGoals() {
		if (goalsList.length > 0) {
			return (
				<div>
					{goalsList.map((goal) => {
						if (goal.focus == 0) {
							return (
								<div className='listItemNoAction'>
									<p className='textBox'>{goal.goal}</p>
									<div className='dataRow' style={{ marginTop: '20px' }}>
										<button className='goalsButton' onClick={() => prioGoal(goal.goal)}>Prioritize Goal</button>
										<button className='goalsButton' onClick={() => removeGoal(goal.goal)}>Remove Goal</button>
									</div> 
								</div>
							);
						}
						else {
							return (
								<div className='listItemFocused'>
									<p className='textBox'>{goal.goal}</p>
									<p>PRIORITIZED</p>
								</div>		
							);
                        }
					})}
				</div> 
			);
		}
		else {
			return (
				<div>
					<h2>No goals</h2> 
				</div> 
			);
        }
    }

	async function addGoal(goal) {
		await fetch('https://slashsolutions.co/UComment/createGoal.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'name=' + employeeName + '&goal=' + goal + '&targetdb=' + userInfo.db
		})
			.then((resp) => resp.json())
			.then((resp) => {
				console.log(resp);
				toggleShowAddGoal()
			})
			.catch((error) => {
				console.log('myerror: ' + error);
			})
	}

	async function removeGoal(goal) {
		await fetch('https://slashsolutions.co/UComment/deleteGoal.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'name=' + employeeName + '&goal=' + goal + '&targetdb=' + userInfo.db
		})
			.then((resp) => resp.json())
			.then((resp) => {
				console.log(resp);
			})
			.catch((error) => {
				console.log(error); 
            })
    }

	async function prioGoal(goal) {
		await fetch('https://slashsolutions.co/UComment/focusGoal.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'name=' + employeeName + '&goal=' + goal + '&targetdb=' + userInfo.db
		})
			.then((resp) => resp.json())
			.then((resp) => {
				console.log(resp);
			})
			.catch((error) => {
				console.log('myerror: ' + error);
			})
	}

	async function refreshGoals() {
		if (!mounted) { //do not refresh if component is not mounted
			return; 
		}

		console.log('using: ' + employeeName + ' : ' + userInfo.db);

		await fetch('https://slashsolutions.co/UComment/getGoals.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'name=' + employeeName + '&targetdb=' + userInfo.db
		})
			.then((resp) => resp.json())
			.then((resp) => {
				if (Array.isArray(resp)) {
					setGoalsList(resp);
					console.log(resp);
                }
			})
			.catch((error) => {
				console.log('myerror: ' + error); 
            })
    }
}

export default Goals; 