import React, { useState } from 'react';
import { BsStarFill } from 'react-icons/bs'; 
import ReactStars from 'react-rating-stars-component'; 
import moment from 'moment'; 

import  './css2/popup.css';


function AddComment(props) {
	const [useManualDate, setUseManualDate] = useState(false);
	const [manDate, setManDate] = useState('2021-10-26');
	const [myRating, setMyRating] = useState(1);
	const [theirRating, setTheirRating] = useState(1); 
	const [type, setType] = useState("phone"); 

	///MAIN RENDER
	return (
		<div className='popup'>
			<div className='container2'>
				{renderForm()}
			</div>
		</div>
	);

	function pullDownSelector() {
		if (props.subTrigger != null) {
			return;
        }

		return (
			<div className='pullDownContainer'>
				<p className='pullDownLabel'>Type:</p> 
				<select id='pullDownType' className='pullDownList' onChange={() => setType(document.getElementById('pullDownType').value)}>
					<option value='phone' className='pullDownOption' selected>Phone</option>
					<option value='ridealong' className='pullDownOption'>Ridealong</option>
					<option value='email' className='pullDownOption'>Email</option>
					<option value='person' className='pullDownOption'>Person</option>
				</select>
			</div> 
		);
    }

	function renderForm() {
		return (
			<div>
				<h1 className='commentTitle'>New Comment</h1> 
				{pullDownSelector()}
				<div className='inputFieldContainer'>
					<textarea className='inputField1' id='commentInput' type='text' spellCheck={true} />
				</div>
				{renderManDate()}
				{renderRatingsForm()}
				<div className='buttonRow'>
					<button className='actionButton' onClick={() => props.handleClose()}>Cancel</button>
					<div style={{ flex: 2 }} />                                                            
					<button className='actionButton' onClick={() => submit()}>Submit Comment</button>
				</div>
			</div>
		);
	}

	function renderManDate() {
		if (useManualDate) {
			return (
				<div className='dateContainer'>
					<p className='dateLabel'>Date(mm/dd/yyyy): </p>
					<input type='text' id='mandate' className='dateInput' />
					<button className='dateButton' onClick={() => setUseManualDate(!useManualDate)}>Use Auto Date</button>
				</div>
			);
		}
		else {
			return (
				<div className='dateContainer'>
					<p className='dateLabel'>{moment().format("MM/DD/YYYY")}</p>
					<button className='dateButton' onClick={() => setUseManualDate(!useManualDate)}>Use Manual Date</button>
				</div> 
			);
        }
    }

	function renderRatingsForm() {
		try {
			var type = document.getElementById('pullDownType').value;
		}
		catch (e) {
			console.log(e);
			console.log(document.getElementById('pullDownType'));
        }

		if (type == 'ridealong') return (
			<div className='ratingsFormContainer'>
				<div className='ratingsForm'>
					<label className='ratingsLabel' >Manager Rating: </label>
					<ReactStars
						count={10}
						edit={true}
						value={myRating}
						size={35}
						onChange={(val) => {
							setMyRating(val);
                        }}
						isHalf={false}
						emptyIcon={<BsStarFill />}
						halfIcon={<BsStarFill />}
						fullIcon={<BsStarFill />}
						activeColor="#ffd700"
					/>
				</div>
				<div className='ratingsForm'>
					<label className='ratingsLabel' >Employee Rating: </label>
					<ReactStars
						count={10}
						edit={true}
						value={theirRating}
						size={35}
						onChange={(val) => {
							setTheirRating(val);
						}}
						isHalf={false}
						emptyIcon={<BsStarFill />}
						halfIcon={<BsStarFill />}
						fullIcon={<BsStarFill />}
						activeColor="#ffd700"
					/>
				</div>
			</div>
		);
	}

	function submit() {
		if (props.subTrigger != null) {
			props.handleSubmit(document.getElementById('commentInput').value);
			return;
		}
		
		if (type == 'ridealong') {
			if (useManualDate) {
				props.handleSubmit(
					document.getElementById('commentInput').value,
					type,
					myRating,
					theirRating,
					document.getElementById('mandate').value,
				);
			}
			else {
				props.handleSubmit(
					document.getElementById('commentInput').value,
					type,
					myRating,
					theirRating,
				);
			}
		}
		else {
			if (useManualDate) {
				props.handleSubmit(
					document.getElementById('commentInput').value,
					type,
					null, 
					null,
					document.getElementById('mandate').value,
				);
			}
			else {
				props.handleSubmit(
					document.getElementById('commentInput').value,
					type,
				);
			}
        }
    }
}

export default AddComment;