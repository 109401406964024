import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import './css2/main1.css'; 
import Menu from './Menu.js';
import Alert from './Alert.js';
import Loader from './Loading.js'; 


function MainPage() {
	const history = useHistory();
	const location = useLocation();

	const [employeeList, setEmployeeList] = useState([]);
	const [userInfo, setUserInfo] = useState(location.state.userInfo);
	const [showAlert, setShowAlert] = useState(false); 
	const [alertMsg, setAlertMsg] = useState(); 
	const [showLoader, setShowLoader] = useState(true);



	var mounted;  
	useEffect(() => {
		mounted = true;
		refreshList(); 

		const interval = setInterval(() => {
			refreshList();
		}, 1500)

		return (() => {
			clearInterval(interval);
			mounted = false
		}); //mounted is checked before fetching to ensure no fetches are done for unmounted components
	}, []);

	//MAIN RENDER
	return (
		<div className='page'>
			{showAlert && <Alert 
				msg={alertMsg}
				cancelHandler={toggleAlert}
				continueHandler={toggleAlert}
			/>}
			<Menu
				components={[
				]}
				userInfo={userInfo}
				logout={true}
			/> 
			<div className='contentPanel'>
				<h1 className='title' >Employee List</h1>
				<div className='list'>
					<div autoComplete='false' className='filterContainer'>
						<p className='filterTitle'>Filter</p> 
						<input autoComplete='false' id='filterInput' className='filterInput' />
						<div className='filterOptions'>
							<div> 
								<input id='nameBox' onClick={(e) => enforceOneCheck(e, 'nameBox')} className='filterOption' type='checkbox' value='name' />
								<label>Name</label>
							</div>
							<div> 
								<input id='teamBox' onClick={(e) => enforceOneCheck(e, 'teamBox')} className='filterOption' type='checkbox' value='team' /> 
								<label>Team</label>
							</div> 
						</div> 
					</div> 
					{renderList()}
				</div>
			</div> 
		</div>
	);

	function enforceOneCheck(e, sel) {
		if (sel == 'nameBox') {
			document.getElementById('teamBox').checked = false;
		}
		else {
			document.getElementById('nameBox').checked = false;
        }
    }

	function toggleLoader() {
		setShowLoader(!showLoader); 
    }

	function toggleAlert(msg) {
		setAlertMsg(msg); 
		setShowAlert(!showAlert); 
    }

	function renderList() {
		if (employeeList == null || employeeList.length === 0) {
			return (
				<div style={{ height: '100%' }}>
					<Loader /> 
				</div>
			)
		}
		else {
			let filterStr = document.getElementById('filterInput').value; 
			console.log(filterStr); 
			let newList = [];
			if (document.getElementById('nameBox').checked) { //filter on name
				newList = employeeList.filter((emp) => emp.name.toLowerCase().includes(filterStr.toLowerCase()))
			}
			else if (document.getElementById('teamBox').checked) { //filter on team
				newList = employeeList.filter((emp) => emp.team.toLowerCase().includes(filterStr.toLowerCase()))
			}
			else { //no filter
				newList = employeeList;
			}

			return (
				<ul style={{ paddingTop: '20px' }}>
					{newList.map((user) =>
						<div className='listItemRounded' key={user.name}>
							<div className='innerContainer' onClick={() => focusUser(user.name)}>
								<div style={{ flex: 1 }} />
								<p className='employeeListEmployee'>{user.name}</p>
								<p className='employeeListTeam'>{user.team}</p>
							</div>
						</div>
					)}
				</ul>
			);
        }
    }

	function focusUser(name) {
		history.push('/comment', { employeeName: name, userInfo: userInfo });
    }

	async function refreshList() {
		if (!mounted) { //if this component is not mounted, do not fetch! (there is no point, and is potential memory leak!!) 
			return; 
        }

		await fetch('https://slashsolutions.co/UComment/getAllNames.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'acctName='+userInfo.username+'&targetdb='+userInfo.db
		})
		.then((resp) => resp.json())
		.then((resp) => {
			if (Array.isArray(resp)) {
				setEmployeeList(resp);
				//setShowLoader(false); 
			}
			else {
				//setShowLoader(false); 
				return;
            }
		})
		.catch((error) => {
			console.log("Error: " + error);
		})
	}
}

export default MainPage;