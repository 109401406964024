import React from 'react';

import './css2/alert.css'; 

function Alert(props) {
	/*props:
	 * msg - string to display
	 * cancelHandler - function to call when cancel clicked (optional)
	 * continueHandler - function to call when continue clicked (optional) 
	 **/

	//main render
	return (
		<div className='popup'>
			<div className='alertContainer'>
				<div className='textContainer'>
					<p className='alertText'>{props.msg}</p>
				</div> 
				<div className='buttonRow'>
					{cancelButton()}
					{continueButton()}
				</div>
			</div> 
		</div> 
	);

	function cancelButton() {
		if (props.cancelHandler != null) {
			return <button className='button' onClick={() => props.cancelHandler()}>Cancel Action</button>
        }
	}

	function continueButton() {
		if (props.continueHandler != null) {
			return <button className='button' onClick={() => props.continueHandler()}>Continue</button> 
        }
    }
}

export default Alert; 