import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import './css2/login.css';
import logo from '../images/logo.png';
import Alert from './Alert.js'; 



function Login() {
	let history = useHistory();
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState(); 

	return (
		<div className='logpage'>
			{showAlert && <Alert
				msg={alertMsg}
				continueHandler={closeAlert}
			/>}
			<div className='loginContainer'>
				<div className='imgContainer'>
					<img src={logo} alt='logo'/>
				</div> 
				<form className='formContainer' onSubmit={(e) => auth(e)}>
					<div className='inputRow'>
						<p className='inputLabel'>Usernamer: </p>
						<input id='inputUsername' type='text' className='inputText'/>
					</div>
					<div className='inputRow'>
						<p className='inputLabel'>Password: </p>
						<input id='inputPassword' type='password' className='inputText' />
					</div>
					<button type='submit' className='submitButton'>Log In</button>
				</form> 
			</div>
		</div>
	);

	function toggleAlert() {
		setShowAlert(!showAlert); 
	}
	function closeAlert() { 
		setShowAlert(!showAlert);
		document.getElementById('inputUsername').value = '';
		document.getElementById('inputPassword').value = ''; 
    }

	function auth(event) {
		event.preventDefault();
		event.stopPropagation();
		//obtain values
		var user = document.getElementById('inputUsername');
		var pass = document.getElementById('inputPassword');

		//check input for syntax.. NONE(/\|)

		//poll server
		Login(user.value, pass.value); //answer comes as {answer: "pass/fail", db: "dbname", perm: "0/1"}
	}

	async function Login(user, pass) {
		await fetch('https://slashsolutions.co/UComment/auth.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'name='+user+'&pass='+pass
		})
		.then((resp) => resp = resp.json())
		.then((resp) => {
			if (resp.answer === "pass") {
				//move on to next screen
				history.push('/main', { userInfo: { username: resp.user, db: resp.db, perm: resp.perm }});
			}
			else {
				//display error and reenter creds
				setAlertMsg('Invalid login creds, please try again.');
				toggleAlert(); 
			}
		})
		.catch((error) => {
			console.log('Error' + error)
		})
	}
}


export default Login;