import React, { useState, useEffect } from 'react';

import './css2/apDetails.css';


function TeamViewer(props) {
	const [employeesList, setEmployeesList] = useState([]);
	const [userInfo, setUserInfo] = useState(props.userInfo);
	const [showAddForm, setShowAddForm] = useState(false); 

	var mounted; 
	useEffect(() => {
		mounted = true;
		refreshEmployees(); 

		return (() => mounted = false); 
    })

	//MAIN RENDER
	return (
		<div className='popup'>
			<div className='container'>
				<h1>{props.team}</h1>
				{renderAddForm()}
				<div className='employeeContainer'> 
					{renderEmployees()}	
				</div>
				<button className='closeButton' onClick={() => props.handleClose()}>Close</button>
			</div>
		</div>
	);

	function renderEmployees() {
		if (employeesList.length == 0 || employeesList == null) {
			return (
				<div>
					<h2>Nothing to display</h2>
				</div>
			)
		}
		else {
			try {
				return (
					<table className='teamsTable'>
						<tr>
							<th>Name</th>
							<th>Delete</th>
						</tr>
						{employeesList.map((emp) =>
							<tr>
								<td>{emp.name}</td>
								<td><button onClick={() => deleteEmployee(emp.name)}>Delete</button></td>
							</tr>
						)}
					</table>
				);
			}
			catch (e) {
				console.log('this happened: ' + e);
			}
		}
	}

	function toggleAddForm() {
		setShowAddForm(!showAddForm); 
	}
	function renderAddForm() {
		if (showAddForm) {
			return (
				<div className='addForm'>
					<label>Employee Name: </label>
					<input id='newEmpName' type='text' />
					<button onClick={() => toggleAddForm()}>Cancel</button>
					<button onClick={() => addEmployee()}>Add</button>
				</div>
			);
		}
		else {
			return (
				<button className='addButton' onClick={() => toggleAddForm()}>Add To Team</button>
			);
        }
	}
	async function addEmployee() {
		let name = document.getElementById('newEmpName').value;

		await fetch('https://slashsolutions.co/UComment/addEmployee.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'targetdb=' + userInfo.db + '&name=' + name + '&team=' + props.team
		})
			.then((resp) => resp.json())
			.then((resp) => {
				setEmployeesList(resp);
			})
			.catch((error) => {
				console.log("Error: " + error);
			})
	}
	async function deleteEmployee(name) {
		await fetch('https://slashsolutions.co/UComment/deleteUser.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'targetdb=' + userInfo.db + '&name=' + name
		})
			.then((resp) => resp.json())
			.then((resp) => {
			})
			.catch((error) => {
				console.log("Error: " + error);
			})
    }

	async function refreshEmployees() {
		if (!mounted) return; //do not fetch if component is not mounted!

		await fetch('https://slashsolutions.co/UComment/getAllNames.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'acctName=adminOverride' + '&targetdb=' + userInfo.db + '&targetTeam=' + props.team
		})
			.then((resp) => resp.json())
			.then((resp) => {
					setEmployeesList(resp);
			})
			.catch((error) => {
				console.log("Error: " + error);
			})
    }
}

export default TeamViewer;