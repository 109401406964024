import React, { useState, useEffect } from 'react';

import './css2/apDetails.css';
import Alert from './Alert.js'; 



function UserViewer(props) {
	/* SEND PROPS:
	 * user array
	 * handleClose
	 * userInfo
	 * */

	const [showAddForm, setShowAddForm] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState();
	const [userInfo, setUserInfo] = useState(props.userInfo); 
	const [teamStr, setTeamStr] = useState(props.user.teamstr);
	const [perm, setPerm] = useState(props.user.perm); 


	//main render
	return (
		<div className='popup'>
			<div className='container'>
				{showAlert && <Alert
					continueHandler={toggleShowAlert}
					msg={alertMsg}
				/>}
				<h1>{props.user.acctname}</h1>
				<div className='permEditor'>
					<button className='permButton' onClick={() => decPerm()}>-</button>
					<h2 className='permText'>Perm: {perm}</h2>
					<button className='permButton' onClick={() => incPerm()}>+</button>
				</div>
				{renderTeams()}
				<button className='closeButton' onClick={() => props.handleClose()}>Close</button>
			</div>
		</div> 
	);

	async function incPerm() {
		let newperm = parseInt(perm) + 1;
		console.log('sending: ' + newperm);

		if (perm >= 2) {
			setAlertMsg('This acct is already promoted to admin!');
			toggleShowAlert();
		}
		else {
			await fetch('https://slashsolutions.co/UComment/updatePerm.php', {
				method: 'POST',
				headers: {
					'content-type': 'application/x-www-form-urlencoded',
				},
				body: 'acctName=' + props.user.acctname + '&newPerm=' + newperm
			})
				.then((resp) => resp.json())
				.then((resp) => {
					console.log('received from incPerm');
					console.log(resp);
					refreshPerm(); 
				})
				.catch((error) => {
					console.log(error); 
                })
		}
	}
	async function decPerm() {
		let newperm = parseInt(perm) - 1;
		console.log('sending: ' + newperm); 

		//CANNOT DEMOTE SELF
		if (props.userInfo.username == props.user.acctname) {
			setAlertMsg('Cannot demote self');
			toggleShowAlert(); 
			return;
        }

		if (perm <= 0) {
			setAlertMsg('Cannot demote this acct further!');
			toggleShowAlert();
			return;
		}
		else {
			await fetch('https://slashsolutions.co/UComment/updatePerm.php', {
				method: 'POST',
				headers: {
					'content-type': 'application/x-www-form-urlencoded',
				},
				body: 'acctName=' + props.user.acctname + '&newPerm=' + newperm
			})
				.then((resp) => resp.json())
				.then((resp) => {
					console.log('received from decPerm');
					console.log(resp);
					refreshPerm(); 
				})
				.catch((error) => {
					console.log(error);
				})
        }
    }

	function toggleShowAlert() {
		setShowAlert(!showAlert);
	}

	function renderTeams() {
		if (teamStr == null || teamStr == '') {
			return (
				<p>Not assigned to any teams</p> 
			);
        }

		let teamsArray = teamStr.split(',');

		//last element in array is likely empty.. remove it if so
		let size = teamsArray.length; 
		if (teamsArray[size - 1] == '') {
			teamsArray.pop(); 
        }

		return (
			<table className='usersTeamList'>
				<tr> 
					<th>Team</th>
					<th>Drop Team Access</th>
				</tr> 
				{teamsArray.map((team) => 
					<tr> 
						<td>{team}</td>
						<td className='clickableCell' onClick={() => removeTeam(team)}>Drop</td>
					</tr> 
                )}
			</table> 
		);
	}

	async function removeTeam(team) {
		await fetch('https://slashsolutions.co/UComment/removeTeam.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
            },
			body: 'acctName=' + props.user.acctname + '&team=' + team + '&targetdb=' + userInfo.db
		})
			.then((resp) => resp.json())
			.then((resp) => {
				refreshTeamStr();
			})
			.catch((error) => {
				console.log(error); 
            })
	}

	async function refreshPerm() {
		await fetch('https://slashsolutions.co/UComment/getPerm.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'acctName=' + props.user.acctname
		})
			.then((resp) => resp.json())
			.then((resp) => {
				console.log('refreshing perm, got: ' + resp.perm); 
				setPerm(resp.perm);
			})
			.catch((error) => {
				console.log(error);
            })
    }

	async function refreshTeamStr() {
		await fetch('https://slashsolutions.co/UComment/getAcctTeams.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'acctName=' + props.user.acctname + '&targetdb=' + userInfo.db
		})
			.then((resp) => resp.json())
			.then((resp) => {
				setTeamStr(resp.teamstr);
			})
			.catch((error) => {
				console.log(error); 
            })
    }
}

export default UserViewer;