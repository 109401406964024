import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { BsStarFill } from 'react-icons/bs';
import ReactStars from 'react-rating-stars-component'; 

import AddCommentPopup from './addCommentPopup.js';
import './css2/main1.css';
import Menu from './Menu.js';
import Loading from './Loading.js'; 


function Discuss() {
	const location = useLocation();
	const history = useHistory(); 

	const [userInfo, setUserInfo] = useState(location.state.userInfo); 
	const [parentComment, setComment] = useState(location.state.comment); //parent comment array ['comment', 'author', 'date']
	const [subCommentsList, setSubComments] = useState([]); 
	const [isOpen, setIsOpen] = useState(false); //for add comment popup
	const [loadingCnt, setLoadingCnt] = useState(1); 

	var mounted;
	useEffect(() => {
		mounted = true;
		refreshSubComments();

		console.log(parentComment);

		const interval = setInterval(() => {
			refreshSubComments();
		}, 1500)

		return (() => {
			clearInterval(interval); 
			mounted = false
		});
	}, []);

	//MAIN RENDER
	return (
		<div className='page'>
			<Menu userInfo={userInfo}
				components={[
					<button className='menuButton' onClick={() => toggleAddCommentPopup()}>Add to Discussion</button>,
				]}
			/>
			<div className='contentPanelDiscuss'>
				<div className='dataRow' style={{ marginTop: '16px' }}>
					<p className='infoTextRow'>Author: {parentComment.author}</p>
					<p className='infoTextRow'>Type: {parentComment.type}</p>
					<p className='infoTextRow'>Date: {parentComment.date}</p>
				</div> 
				<p className='subCommentTitle'>{parentComment.comment}</p>
				{renderRatings()}
				{isOpen && <AddCommentPopup
					handleClose={toggleAddCommentPopup}
					handleSubmit={submitSubComment}
					subTrigger={'notnull'}
				/>}
				{renderSubComments()}
			</div> 
		</div> 
	)

	function renderRatings() {
		if (parentComment.myRating != null && parentComment.theirRating != null) {
			return (
				<div className='ratingsContainer' style={{ width: '100%' }}>
					<div>
						<div className='ratingsRow'>
							<label className='ratingsLabel'>Manager Rating: </label>
							<ReactStars 
								count={10}
								edit={false}
								value={parentComment.myRating}
								size={35}
								isHalf={false}
								emptyIcon={<BsStarFill />}
								halfIcon={<BsStarFill />}
								fullIcon={<BsStarFill/>}
								activeColor="#ffd700"
							/>
						</div> 
					</div> 
					<div style={{ marginTop: '8px' }}>
						<div className='ratingsRow'>
							<label className='ratingsLabel'>Employee Rating: </label>
							<ReactStars
								count={10}
								edit={false}
								size={35}
								value={parentComment.theirRating}
								isHalf={false}
								emptyIcon={<BsStarFill />}
								halfIcon={<BsStarFill />}
								fullIcon={<BsStarFill />}
								activeColor="#ffd700"
							/>
						</div> 
					</div>
				</div> 
			);
        }
    }

	function toggleAddCommentPopup() {
		setIsOpen(!isOpen);
    }

	function renderSubComments() {
		if (subCommentsList.length > 0) {
			return (
				<div className='list'>
					{subCommentsList.map((subComment) => 
						<div className='listItemNoAction'>
							<div className='dataColumn' key={subComment.comment}>
								<p className='infoText'>Author: {subComment.author}</p>
								<p className='infoText'>Date: {subComment.date}</p>
							</div> 
							<p className='textBox'>{subComment.comment}</p>
						</div> 
					)}
				</div>
			)
		}
		else {
			if (loadingCnt >= 4) {
				return (
					<div className='list'>
						<h2>No comments found</h2> 
					</div>
				)
            }
			return (
				<div className='list'>
					<Loading /> 
				</div> 
			)
        }
    }

	async function refreshSubComments() {
		if (!mounted) {
			return; //see MainPage.js:refreshList() for details
        }

		await fetch('https://slashsolutions.co/UComment/getSubComments.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'targetdb='+userInfo.db+'&commentstr='+parentComment.comment
		})
			.then((resp) => resp.json())
			.then((resp) => {
				setLoadingCnt((mystate) => mystate + 1);
				if (resp == "norows") {
					return;
				} else {
					setSubComments(resp);
				}
			})
			.catch((error) => {
				console.log(error); 
            })
	}

	function submitSubComment(comment) {
		//check that comment.value is good
		addSubComment(comment);
		toggleAddCommentPopup();
	}

	async function addSubComment(comment) {
		let myDate = moment().format("MM/DD/YYYY");

		await fetch('https://slashsolutions.co/UComment/addSubComment.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'targetdb='+userInfo.db+'&targettable='+parentComment.comment+'&comment='+comment+'&author='+userInfo.username+'&date='+myDate
		})
			.then((resp) => resp.json())
			.then((resp) => {
				console.log(resp); 
            })
    }
}



export default Discuss; 