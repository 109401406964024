import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Alert from './Alert.js'; 
import Menu from './Menu.js'; 
import './css2/main1.css'; 


function Account() {
	const location = useLocation(); 
	const history = useHistory();

	const [userInfo, setUserInfo] = useState(location.state.userInfo);
	const [showForm, setShowForm] = useState(false); 
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState(); 

	//MAIN RENDER
	return (
		<div className='page'>
			<Menu
				title={'Acct Settings'}
				userInfo={userInfo}
				components={[
				]}
			/> 
			{showAlert && <Alert
				msg={alertMsg}
				continueHandler={toggleAlert}
			/>}
			<div className='contentPanel'>
				<h1 className='title'>Account Settings</h1> 
				<div className='noListPage'>
					{renderForm()} 
				</div> 
			</div> 
		</div>
	);

	function renderForm() {
		if (showForm) {
			return (
				<div>
					<label>Old Password: </label>
					<input type='text' id='oldpass' />
					<br />
					<label>New Password: </label>
					<input type='text' id='newpass1' />
					<br /> 
					<label>Confirm Password: </label>
					<input type='text' id='newpass2' />
					<br />
					<button onClick={() => toggleForm()}>Cancel</button>
					<button onClick={() => authPass()}>Submit</button> 
				</div>
			);
		}
		else {
			return (
				<button onClick={() => toggleForm()}>Change password</button>
			);
        }
    }

	async function authPass() {
		let pass1 = document.getElementById('newpass1').value;
		let pass2 = document.getElementById('newpass2').value;
		if (pass1 != pass2) {
			setAlertMsg('New passwords do not match!');
			toggleAlert();
			clearForm();
			return;
        }

		let pass = document.getElementById('oldpass').value;

		await fetch('https://slashsolutions.co/UComment/auth.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'name=' + userInfo.username + '&pass=' + pass
		})
			.then((resp) => resp = resp.json())
			.then((resp) => {
				if (resp.answer === "pass") {
					//move on to next screen
					changePass();
				}
				else {
					//display error and reenter creds
					setAlertMsg('Invalid login creds, please try again.');
					toggleAlert();
				}
			})
			.catch((error) => {
				console.log('Error' + error)
			})
    }

	async function changePass() {
		let newPass = document.getElementById('newpass1').value;

		await fetch('https://slashsolutions.co/UComment/newpass.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'db=' + userInfo.db + '&acctName=' + userInfo.username + '&newpass=' + newPass
		})
			.then((resp) => resp.json())
			.then((resp) => {
				setAlertMsg(resp);
				toggleAlert();
				clearForm();
			})
			.catch((error) => {
				console.log(error);
            })
    }

	function clearForm() {
		document.getElementById('oldpass').value = '';
		document.getElementById('newpass1').value = '';
		document.getElementById('newpass2').value = '';
    }

	function toggleAlert() {
		setShowAlert(!showAlert);
    }

	function toggleForm() {
		setShowForm(!showForm);
    }
}

export default Account; 