import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { GoGraph } from 'react-icons/go';

import AddCommentPopup from './addCommentPopup.js';
import GraphPopup from './graphPopup.js'; 
import './css2/main1.css';
import Menu from './Menu.js';
import Alert from './Alert.js'; 
import Loading from './Loading.js';


function Comment() {
	const history = useHistory();
	const location = useLocation();

	const [userInfo, setUserInfo] = useState(location.state.userInfo); 
	const [employeeName, setEmployeeName] = useState(location.state.employeeName);
	const [commentsList, setCommentsList] = useState([]);
	const [isOpen, setIsOpen] = useState(false); //for comment popup
	const [focusedGoal, setFocusedGoal] = useState(); 
	const [showGraph, setShowGraph] = useState(false);

	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState(); 

	const [showLoader, setShowLoader] = useState(true); //true shows loading symbol on top
	const [loadingCnt, setLoadingCnt] = useState(0); 



	var mounted;
	useEffect(() => {
		mounted = true;
		refreshComments();
		refreshGoals(); 

		const interval = setInterval(() => {
			refreshComments();
			refreshGoals();
		}, 1500)

		return (() => {
			clearInterval(interval); 
			mounted = false
		});
	}, []);

	//MAIN RENDER
	return (
		<div className='page'>
			{showAlert && <Alert
				msg={alertMsg}
				continueHandler={toggleAlert}
			/>}
			{showGraph && <GraphPopup
				employeeName={employeeName}
				userInfo={userInfo}
				handleClose={toggleShowGraph}
			/>}
			<Menu
				userInfo={userInfo}
				components={[
					<button className='menuButton' onClick={() => toggleAddComment()}>New Comment</button>,
					<button className='menuButton' onClick={() => openGoals()}>{employeeName}'s Goals</button>,
				]}
			/>
			<div className='contentPanel'>
				<div className='dataRow'>
					<div style={{ }}/>
					<h1 className='title'>{employeeName}</h1>
					<GoGraph size={40} className='graphButton' onClick={() => toggleShowGraph()} /> 
				</div> 
				{isOpen && <AddCommentPopup
					handleClose={toggleAddComment}
					handleSubmit={submitComment}
				/>}
				{renderComments()}
			</div> 
		</div> 
	)

	function toggleShowGraph() {
		setShowGraph(!showGraph); 
    }

	function toggleAlert() {
		setShowAlert(!showAlert);
    }

	function renderFocusedGoal() {
		if (focusedGoal != null) {
			return (
				<p>{focusedGoal}</p> 
			);
        }
    }

	function openGoals() {
		history.push('goals', { userInfo: userInfo, employee: employeeName });
    }

	function toggleAddComment() {
		setIsOpen(!isOpen);
    }

	function renderComments() {
		if (commentsList != null && commentsList.length > 0) {
			return (
				<div className='list'>
					{commentsList.map((comment) => 
						<div className='listItem' key={comment.comment} onClick={() => history.push('/discuss', { comment: comment, userInfo: userInfo })}>
							<div className='dataColumn'>
								<p className='infoText'>Author: {comment.author}</p>
								<p className='infoText'>Type: {comment.type}</p>
								<p className='infoText'>Date: {comment.date}</p>
							</div> 
							<p className='textBox'>{comment.comment}</p>
						</div>
					)}
				</div> 
			)
		}
		else {
			if (loadingCnt >= 4) {
				return (
					<div className='list'>
						<h2>Could not find any comments</h2>
					</div>
				)
			}
			else {
				return (
					<div className='list'>
						<Loading />
					</div>
				)
			}
        }
	}

	async function refreshComments() {
		if (!mounted) {
			return; //see MainPage.js:refreshList() for details
        }

		await fetch('https://slashsolutions.co/UComment/getComments.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'target='+employeeName+'&targetdb='+userInfo.db
		})
			.then((resp) => resp.json())
			.then((resp) => {
				setLoadingCnt((mystate) => mystate + 1); 
				if (resp == "norows") {
					console.log("No Comments. Add one!");
				}
				else {
					if (mounted) {
						setCommentsList(resp);
					}
                }
            })
	}

	async function refreshGoals() {
		if (!mounted) { //do not refresh if component is not mounted
			return;
		}

		await fetch('https://slashsolutions.co/UComment/getGoals.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: 'name=' + employeeName + '&targetdb=' + userInfo.db
		})
			.then((resp) => resp.json())
			.then((resp) => {
				if (Array.isArray(resp)) {
					resp.map((goal) => {
						if (goal.focus == 1) {
							setFocusedGoal(goal.goal); 
                        }
                    })
				}
			})
			.catch((error) => {
				console.log('myerror: ' + error);
			})
	}

	function submitComment(comment, type, myrating, theirrating, date) {
		//check that comment is good
		addComment(comment, type, myrating, theirrating, date);
		toggleAddComment();
    }

	async function addComment(comment, type,  myrating, theirrating, date) {
		let myDate;
		if (date == null) {
			myDate = moment().format("MM/DD/YYYY");
		}
		else {
			myDate = date;
        }

		let theBody;
		if (myrating != null && theirrating != null) {
			theBody = 'targetdb=' + userInfo.db +
				'&target=' + employeeName +
				'&comment=' + encodeURIComponent(comment) +
				'&author=' + userInfo.username +
				'&date=' + myDate +
				'&type=' + type +
				'&theirrating=' + theirrating +
				'&myrating=' + myrating;
		}
		else {
			theBody = 'targetdb=' + userInfo.db +
				'&target=' + employeeName +
				'&comment=' + encodeURIComponent(comment) +
				'&author=' + userInfo.username +
				'&date=' + myDate +
				'&type=' + type;
        }

		await fetch('https://slashsolutions.co/UComment/addComment.php', {
			method: 'POST',
			headers: {
				'content-type': 'application/x-www-form-urlencoded',
			},
			body: theBody
		})
			.then((resp) => resp.json())
			.then((resp) => {
				setAlertMsg(resp.answer);
				toggleAlert(); 
				console.log(resp);
            })
    }
}



export default Comment;