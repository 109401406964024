import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import './App.css';
import Login from './components/Login.js';
import Main from './components/MainPage.js';
import Comment from './components/Comment.js';
import Discuss from './components/Discuss.js'; 
import Account from './components/Account.js'; 
import Admin from './components/Admin.js'; 
import Goals from './components/Goals.js'; 
import Contact from './components/Contact.js'; 



class App extends React.Component {
	render() {
		return (
			<div>
				<Router>
					<Switch>
						<Route path='/' exact>
							<Login />
						</Route>
						<Route path='/main' exact>
							<Main />
						</Route>
						<Route path='/comment' exact>
							<Comment />
						</Route>
						<Route path='/discuss' exact> 
							<Discuss />
						</Route>
						<Route path='/account' exact>
							<Account /> 
						</Route>
						<Route path='/admin' exact>
							<Admin />
						</Route> 
						<Route path='/goals' exact>
							<Goals /> 
						</Route>
						<Route path='/contact' exact>
							<Contact /> 
						</Route> 
					</Switch>
					{/*<Footer />*/}
				</Router>
			</div>
		);
	}
}

export default App;
