import React from 'react';

import './css2/loading.css';


function Loading() {
	return (
		<div className='loaderBackground'>
			<div className='loader' />
		</div> 
	);
} 

export default Loading;